import AlertMessage from '../AlertMessage/AlertMessage';
import ModalCustom from '../ModalCustom/ModalCustom';
import React, { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { setErrorApp, setInfoApp } from '../../store/reducers/InfoSlice';
import BalanceModal from '../BalanceModal/BalanceModal';
import {
	setBalanceModal,
	setBitrixModal,
	setSettingsEvaluationModal,
	setSettingsModal,
	setSettingsProfileModal,
	setSettingsVoiceMailModal,
	setSettingsDeliveryModal,
	setVideoBalanceModal,
} from '../../store/reducers/ModalSlice';
import SettingsForm from '../SettingsForm/SettingsForm';
import { setDataPage } from '../../store/reducers/PagesSlice';
import ProfileForm from '../ProfileForm/ProfileForm';
import BitrixModal from '../BitrixModal/BitrixModal';
import { setView } from '../../store/reducers/ViewSlice';
import SettingsEvalForm from '../SettingsEvalForm/SettingsEvalForm';
import VideoBalanceModal from '../VideoBalanceModal/VideoBalanceModal';
import SettingsVoiceMailForm from '../SettingsVoiceMailForm/SettingsVoiceMailForm';
import SettingsDeliveryForm from '../SettingsDeliveryForm/SettingsDeliveryForm';

const MessageWrapper: FC = () => {
	const dispatch = useAppDispatch();
	const view = useAppSelector((state) => state.viewReducer);
	const { errorApp, isErrorApp, infoApp, isInfoApp } = useAppSelector(
		(state) => state.infoReducer
	);
	const { balanceModal } = useAppSelector((state) => state.modalsReducer);
	const { settingsModal } = useAppSelector((state) => state.modalsReducer);
	const { settingsEvaluationModal } = useAppSelector(
		(state) => state.modalsReducer
	);
	const { settingsProfileModal } = useAppSelector(
		(state) => state.modalsReducer
	);
	const { settingsVoiceMailModal } = useAppSelector(
		(state) => state.modalsReducer
	);
	const { settingsDeliveryModal } = useAppSelector(
		(state) => state.modalsReducer
	);
	const { bitrixModal } = useAppSelector((state) => state.modalsReducer);
	const { videoBalanceModal } = useAppSelector((state) => state.modalsReducer);
	const handleCloseModal = () => {
		dispatch(setInfoApp({ isInfoApp: false, infoApp: '' }));
	};

	const onCloseAlertMessageApp = (event: React.MouseEvent, reason: string) => {
		if (reason === 'clickaway') {
			return;
		}
		dispatch(setErrorApp({ isErrorApp: false, errorApp: '' }));
	};
	const setCloseModal = () => {
		dispatch(
			setBalanceModal({
				show: false,
			})
		);
	};
	const setCloseVideoBalanceModal = () => {
		dispatch(
			setVideoBalanceModal({
				show: false,
			})
		);
	};
	const setCloseSettingsModal = () => {
		dispatch(
			setSettingsModal({
				show: false,
			})
		);
		dispatch(() => {
			if (view.view === 'orders') {
				dispatch(setDataPage('Заказы'));
			}
			if (view.view === 'log') {
				dispatch(setDataPage('Журнал событий'));
			}
		});
		dispatch(setView(view.view));
	};
	const setCloseSettingsProfileModal = () => {
		dispatch(
			setSettingsProfileModal({
				show: false,
			})
		);
		dispatch(() => {
			if (view.view === 'orders') {
				dispatch(setDataPage('Заказы'));
			}
			if (view.view === 'log') {
				dispatch(setDataPage('Журнал событий'));
			}
		});
		dispatch(setView(view.view));
	};
	const setCloseSettingsEvaluationModal = () => {
		dispatch(
			setSettingsEvaluationModal({
				show: false,
			})
		);
		dispatch(() => {
			if (view.view === 'orders') {
				dispatch(setDataPage('Заказы'));
			}
			if (view.view === 'log') {
				dispatch(setDataPage('Журнал событий'));
			}
		});
		dispatch(setView(view.view));
	};
	const setCloseSettingsVoiceMailModal = () => {
		dispatch(
			setSettingsVoiceMailModal({
				show: false,
			})
		);
		dispatch(() => {
			if (view.view === 'orders') {
				dispatch(setDataPage('Заказы'));
			}
			if (view.view === 'log') {
				dispatch(setDataPage('Журнал событий'));
			}
		});
		dispatch(setView(view.view));
	};
	const setCloseSettingsDeliveryModal = () => {
		dispatch(
			setSettingsDeliveryModal({
				show: false,
			})
		);
		dispatch(() => {
			if (view.view === 'orders') {
				dispatch(setDataPage('Заказы'));
			}
			if (view.view === 'log') {
				dispatch(setDataPage('Журнал событий'));
			}
		});
		dispatch(setView(view.view));
	};
	const setCloseBitrixModal = () => {
		dispatch(
			setBitrixModal({
				show: false,
			})
		);
	};

	return (
		<>
			<AlertMessage
				open={isErrorApp}
				close={onCloseAlertMessageApp}
				severity={'error'}
				message={errorApp}
			/>
			<ModalCustom
				open={isInfoApp}
				handleClose={handleCloseModal}
				text={infoApp}
			/>
			<BalanceModal open={balanceModal.show} setClose={setCloseModal} />
			<BitrixModal open={bitrixModal.show} setClose={setCloseBitrixModal} />
			<SettingsForm
				open={settingsModal.show}
				setClose={setCloseSettingsModal}
			/>
			<ProfileForm
				open={settingsProfileModal.show}
				handleClose={setCloseSettingsProfileModal}
			/>
			<SettingsEvalForm
				open={settingsEvaluationModal.show}
				setClose={setCloseSettingsEvaluationModal}
			/>
			<SettingsVoiceMailForm
				open={settingsVoiceMailModal.show}
				setClose={setCloseSettingsVoiceMailModal}
			/>
			<SettingsDeliveryForm
				open={settingsDeliveryModal.show}
				setClose={setCloseSettingsDeliveryModal}
			/>
			<VideoBalanceModal
				open={videoBalanceModal.show}
				setClose={setCloseVideoBalanceModal}
			/>
		</>
	);
};

export default MessageWrapper;
