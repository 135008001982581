import { FC } from 'react';
import styles from './header.module.css';
import Profile from '../Icons/Profile/Profile';
import BalanceButton from '../Buttons/balanceButton/BalanceButton';
import BalanceVideoButton from '../Buttons/balanceVideoButton/BalanceVideoButton';
import { useAppSelector } from '../../hooks/redux';
import Wallet from '../Icons/Wallet/Wallet';
import ArrowBottomIcon from '../Icons/ArrowBottom/ArrowBottomIcon';
import { useDispatch } from 'react-redux';
import {
	setBalanceModal,
	setSettingsEvaluationModal,
	setSettingsModal,
	setSettingsProfileModal,
	setSettingsVoiceMailModal,
	setSettingsDeliveryModal,
	setVideoBalanceModal,
} from '../../store/reducers/ModalSlice';
import NavMenuButton from '../Buttons/navMenuButton/NavMenuButton';
import Log from '../Icons/Log/Log';
import { setView } from '../../store/reducers/ViewSlice';
import { setDataPage } from '../../store/reducers/PagesSlice';
import Play from '../Icons/Play/Play';
import Settings from '../Icons/Settings/Settings';
import Counter from '../Icons/Counter/Counter';
import LogoutButton from '../LogoutButton/LogoutButton';
import LinksServices from '../LinksServices/LinksServices';
import CrmTools from '../Icons/Crmtools';
import { Button } from '@mui/material';

const Header: FC = () => {
	const dispatch = useDispatch();
	const { profile } = useAppSelector((state) => state.profileReducer);
	const page = useAppSelector((state) => state.pagesReducer);

	const onSetViewCalls = (text: string) => {
		if (text !== page.data) {
			dispatch(setDataPage(text));
			dispatch(setView('log'));
		}
	};

	const onSetViewOrders = (text: string) => {
		if (text !== page.data) {
			dispatch(setDataPage(text));
			dispatch(setView('orders'));
		}
	};

	const showSettingsModal = (text: string) => {
		if (text !== page.data) {
			dispatch(
				setSettingsModal({
					show: true,
				})
			);
			dispatch(setDataPage(text));
		}
	};

	const showSettingsProfileModal = (text: string) => {
		if (text !== page.data) {
			dispatch(
				setSettingsProfileModal({
					show: true,
				})
			);
			dispatch(setDataPage(text));
		}
	};

	const showSettingsEvaluationModal = (text: string) => {
		if (text !== page.data) {
			dispatch(
				setSettingsEvaluationModal({
					show: true,
				})
			);
			dispatch(setDataPage(text));
		}
	};

	const showSettingsVoiceMailModal = (text: string) => {
		if (text !== page.data) {
			dispatch(
				setSettingsVoiceMailModal({
					show: true,
				})
			);
			dispatch(setDataPage(text));
		}
	};

	const showSettingsDeliveryModal = (text: string) => {
		if (text !== page.data) {
			dispatch(
				setSettingsDeliveryModal({
					show: true,
				})
			);
			dispatch(setDataPage(text));
		}
	};

	const showModal = () => {
		dispatch(
			setBalanceModal({
				show: true,
			})
		);
	};

	const showVideoBalanceModal = () => {
		dispatch(
			setVideoBalanceModal({
				show: true,
			})
		);
	};

	return (
		<header className={styles.header}>
			<div className={styles.header__centralized_container}>
				<div className={styles.header__left_side}>
					<div className={styles.header__logo}>
						<div className={styles.header__services}>
							<LinksServices />
						</div>
						<CrmTools />
						<span>обработка заказов</span>
						<p>|</p>
						<p>Kloud.one</p>
					</div>
				</div>
				<div className={styles.header__right_side}>
					<Profile />
					<Button className={styles.logoutButton}>
						<p>{profile?.display_name ?? ''}</p>
					</Button>
					<LogoutButton />
				</div>
			</div>
			<div className={styles.navbar}>
				<div className={styles.navbar_header}>
					<div className={styles.navbar_header__container}>
						<NavMenuButton
							text="Заказы"
							page={page.data}
							onClick={onSetViewOrders}
							icon={<Counter />}
						/>
						<NavMenuButton
							text="Журнал событий"
							page={page.data}
							onClick={onSetViewCalls}
							icon={<Log />}
						/>
						<NavMenuButton
							text="Настройки подключения"
							page={page.data}
							onClick={showSettingsProfileModal}
							icon={<Settings />}
						/>
						<NavMenuButton
							text="Подтверждение заказов"
							page={page.data}
							onClick={showSettingsModal}
							icon={<Play />}
						/>
						<NavMenuButton
							text="Оценка качества"
							page={page.data}
							onClick={showSettingsEvaluationModal}
							icon={<Play />}
						/>
						<NavMenuButton
							text="Голосовые рассылки"
							page={page.data}
							onClick={showSettingsVoiceMailModal}
							icon={<Play />}
						/>
						<NavMenuButton
							text="Подтверждение доставки"
							page={page.data}
							onClick={showSettingsDeliveryModal}
							icon={<Play />}
						/>
					</div>
					<div className={styles.navbar_header__container}>
						<div className={styles.navbar_header__balance}>
							<BalanceVideoButton
								currentDiskSize={profile?.current_disk_size_shop ?? 0}
								totalDiskSize={profile?.total_disk_size_shop ?? 0}
								onClick={showVideoBalanceModal}
								icon={<Wallet />}
								arrow={<ArrowBottomIcon />}
							/>
						</div>
						<div className={styles.navbar_header__balance}>
							<BalanceButton
								balance={profile?.balance_shop ?? 0}
								text="звонков"
								onClick={showModal}
								icon={<Wallet />}
								arrow={<ArrowBottomIcon />}
							/>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
