import { createSlice } from '@reduxjs/toolkit';

type ModalState = {
	balanceModal: { show: boolean },
	settingsModal: { show: boolean },
	settingsProfileModal: { show: boolean },
	settingsEvaluationModal: { show: boolean },
	settingsVoiceMailModal: { show: boolean },
	settingsDeliveryModal: { show: boolean },
	bitrixModal: { show: boolean },
	videoBalanceModal: { show: boolean },
};

const initialState: ModalState = {
	balanceModal: {
		show: false,
	},
	settingsModal: {
		show: false,
	},
	settingsProfileModal: {
		show: false,
	},
	bitrixModal: {
		show: false,
	},
	settingsEvaluationModal: {
		show: false,
	},
	settingsVoiceMailModal: {
		show: false,
	},
	settingsDeliveryModal: {
		show: false,
	},
	videoBalanceModal: {
		show: false,
	},
};

export const modalsSlice = createSlice({
	name: 'modals',
	initialState,
	reducers: {
		setBalanceModal: (state, data) => {
			state.balanceModal = data.payload;
		},
		setSettingsModal: (state, data) => {
			state.settingsModal = data.payload;
		},
		setSettingsProfileModal: (state, data) => {
			state.settingsProfileModal = data.payload;
		},
		setBitrixModal: (state, data) => {
			state.bitrixModal = data.payload;
		},
		setSettingsEvaluationModal: (state, data) => {
			state.settingsEvaluationModal = data.payload;
		},
		setSettingsVoiceMailModal: (state, data) => {
			state.settingsVoiceMailModal = data.payload;
		},
		setSettingsDeliveryModal: (state, data) => {
			state.settingsDeliveryModal = data.payload;
		},
		setVideoBalanceModal: (state, data) => {
			state.videoBalanceModal = data.payload;
		},
	},
});

export const {
	setBalanceModal,
	setSettingsModal,
	setSettingsProfileModal,
	setBitrixModal,
	setSettingsEvaluationModal,
	setSettingsVoiceMailModal,
	setSettingsDeliveryModal,
	setVideoBalanceModal,
} = modalsSlice.actions;

export default modalsSlice.reducer;
