export const columnsOrders = [
	{
		headerName: 'Номер заказа',
		field: 'order_number',
		input: true,
		width: 150,
	},
	{
		headerName: 'Дата',
		field: 'date',
		width: 150,
		sort: true,
	},
	{
		headerName: 'Подтверждение заказов',
		field: 'order_status',
		width: 250,
		select: true,
		sort: false,
		selectValue: 'all',
		menuItem: [
			{ value: 'all', label: 'Все' },
			{ value: 'Требует подтверждения', label: 'Требует подтверждения' },
			{ value: 'Трубку повесили', label: 'Трубку повесили' },
			{ value: 'Подтвержден', label: 'Подтвержден' },
			{ value: 'В процессе', label: 'В процессе' },
			{ value: 'Остановлен', label: 'Остановлен' },
			{ value: 'Отменен', label: 'Отменен' },
			{ value: 'Запланирован', label: 'Запланирован' },
			{ value: 'Ошибка', label: 'Ошибка' },
		],
	},
	{
		headerName: 'Упаковка',
		field: 'package_status',
		width: 250,
		select: true,
		selectValue: 'all',
		sort: false,
		menuItem: [
			{ value: 'all', label: 'Все' },
			{ value: 'Требует упаковки', label: 'Требует упаковки' },
			{ value: 'Видео записано', label: 'Видео записано' },
			{ value: 'Запланирован', label: 'Запланирован' },
			{ value: 'Остановлен', label: 'Остановлен' },
		],
	},
	{
		headerName: 'Оценка',
		field: 'evaluation_status',
		width: 250,
		select: true,
		selectValue: 'all',
		sort: false,
		menuItem: [
			{ value: 'all', label: 'Все' },
			{ value: 'Требует оценки', label: 'Требует оценки' },
			{ value: 'В процессе', label: 'В процессе' },
			{ value: 'Оценка получена', label: 'Оценка получена' },
			{ value: 'Оценка не получена', label: 'Оценка не получена' },
			{ value: 'Остановлен', label: 'Остановлен' },
		],
	},
];
