import React, { FC, useEffect } from 'react';
import {
	Input,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
} from '@mui/material';
import { columnsVideo } from './column';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { returnVideo } from '../../service/VideoService';
import { setErrorApp } from '../../store/reducers/InfoSlice';
import TablePagination from '@mui/material/TablePagination';
import 'rsuite/dist/rsuite.min.css';
import './tableVideo.css';
import { format } from 'date-fns';
import PlayerVideo from '../PlayerVideo/PlayerVideo';
import GetAppIcon from '@mui/icons-material/GetApp';
import {
	setSort,
	setStart,
	setSearch,
	setLoading,
	setPageVideo,
	setSizePerPageVideo,
	setTotalSizeVideo,
	setVideos,
	setOrder,
} from '../../store/reducers/VideoSlice';
import { searchI } from '../../service/OrdersService';
const TableVideo: FC = () => {
	const dispatch = useAppDispatch();
	const { profile } = useAppSelector((state) => state.profileReducer);
	const {
		isLoading,
		order,
		sort,
		page,
		videos,
		skip,
		searchOption,
		totalSize,
		sizePerPage,
		search,
	} = useAppSelector((state) => state.videoReducer);

	const { searchStart, searchEnd } = useAppSelector(
		(state) => state.viewReducer
	);

	const searchObj: searchI = search;

	useEffect(() => {
		(async () => {
			dispatch(setLoading(true));
			const response = await returnVideo(
				page,
				searchOption,
				skip,
				sizePerPage,
				sort,
				order,
				searchStart,
				searchEnd,
				search
			);
			if (response.error) {
				dispatch(setLoading(false));
				dispatch(
					setErrorApp({
						isErrorApp: true,
						errorApp: 'Произошла ошибка при получении списка видео',
					})
				);
			} else {
				dispatch(setLoading(false));
				const { total, data } = response.data.data;
				dispatch(setVideos(data));
				dispatch(setTotalSizeVideo(total));
			}
		})();
	}, [
		page,
		sort,
		sizePerPage,
		searchStart,
		searchEnd,
		search,
		order,
		// dispatch,
		profile,
		searchOption,
		skip,
	]);

	const handleChangePage = (event: any, newPage: any) => {
		dispatch(setPageVideo(newPage + 1));
		dispatch(setStart(newPage * sizePerPage));
	};

	const handleChangeRowsPerPage = (e: any) => {
		dispatch(setSizePerPageVideo(e.target.value));
		dispatch(setPageVideo(1));
		dispatch(setStart(0));
	};

	const createSortHandler = (e: any, field: string) => {
		console.log(e.target.tagName);
		if (
			e.target.tagName === 'svg' ||
			e.target.tagName === 'SPAN' ||
			e.target.tagName === 'path'
		) {
			const isAsc = sort.sortField === field && order.sortOrder === '1';
			dispatch(setPageVideo(1));
			dispatch(
				setSort({
					sortField: field,
				})
			);
			dispatch(
				setOrder({
					sortOrder: isAsc ? '-1' : '1',
				})
			);
		}
	};

	// @ts-ignore
	const customLabel = ({ from, to, count }) => {
		return `${from}-${to} из ${count}`;
	};

	const handleChangeInput = (
		evt: React.ChangeEvent<HTMLInputElement>,
		type: string
	) => {
		// console.log(evt.target.value, type);
		const obj: any = {};
		obj[type] = evt.target.value;
		dispatch(setSearch(obj));
	};

	const renderMediaCell = (row: any) => {
		if (row?.videoLink) {
			return (
				<div className="audioCell">
					<div>
						<a
							className="getAppIconVideo"
							href={`${row?.videoLink}&download=true`}
							type="video/mp4"
						>
							<GetAppIcon />
						</a>
					</div>
					<div>
						<PlayerVideo videoLink={row?.videoLink} />
					</div>
				</div>
			);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const debounce = <T extends (...args: any[]) => any>(
		callback: T,
		waitFor: number
	) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		let timeout: any = 0;
		return (...args: Parameters<T>): ReturnType<T> => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			let result: any;
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				result = callback(...args);
			}, waitFor);
			return result;
		};
	};

	const debouncedHandle = debounce(handleChangeInput, 1000);

	return (
		<div className="container">
			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<TableContainer className="tableContainer">
					<Table className="table" stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow className="row_header">
								{columnsVideo.map((column) => (
									<TableCell
										align="center"
										key={column.field}
										padding="normal"
										style={{
											width: column.width,
										}}
										// @ts-ignore
										sortDirection={
											sort.sortField === column.field && order.sortOrder === '1'
												? 'asc'
												: 'desc'
										}
									>
										{column.headerName}
										{column.sort ? (
											<TableSortLabel
												key={column.field}
												active={sort.sortField === column.field}
												// @ts-ignore
												direction={
													sort.sortField === column.field &&
														order.sortOrder === '1'
														? 'asc'
														: 'desc'
												}
												onClick={(e) => createSortHandler(e, column.field)}
											/>
										) : null}
										{column.input ? (
											<Input
												key={`${column.field}_input`}
												className={column.input ? 'tableCell_input' : ''}
												// id="component-helper"
												onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
													debouncedHandle(evt, `${column.field}`)
												}
												aria-describedby="component-helper-text"
												defaultValue={column.field in searchObj ? searchObj[column.field] : ''}
											/>
										) : null}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{videos?.length > 0 ? (
								videos?.map((row: any, index) => {
									return (
										<TableRow
											className={index % 2 ? 'row_background' : ''}
											key={row?._id}
										>
											<TableCell align="center">
												{format(new Date(row?.date), 'dd/MM/yyyy  kk:mm:ss')}
											</TableCell>
											<TableCell align="center">{row?.order_number}</TableCell>
											<TableCell>
												{row?.videoLink ? (
													<div>{renderMediaCell(row)}</div>
												) : null}
											</TableCell>
										</TableRow>
									);
								})
							) : (
								<TableRow />
							)}
						</TableBody>
					</Table>
					{videos?.length > 0 ? null : (
						<p className="label-no_records">Нет записей</p>
					)}
				</TableContainer>
			</Paper>
			{isLoading ? <LinearProgress /> : null}
			<div className="wrapper-footer row_background">
				<div className="wrapper-buttons"></div>
				<TablePagination
					rowsPerPageOptions={[10, 20, 50, 100, 500]}
					component="div"
					count={totalSize || 0}
					rowsPerPage={sizePerPage}
					page={page - 1}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="На странице:"
					labelDisplayedRows={customLabel}
				/>
			</div>
		</div>
	);
};

export default TableVideo;
