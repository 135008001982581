import { FC } from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { CustomProvider } from 'rsuite';
import ruRU from 'rsuite/locales/ru_RU';
import './tableLogs.css';
import Box from '@mui/material/Box';
import { FormControl, InputLabel } from '@mui/material';
import {
	setSearchEnd,
	setSearchStart,
	setTab,
} from '../../store/reducers/ViewSlice';
import TableCalls from '../TableCalls/TableCall';
import TableVideo from '../TableVideo/TableVideo';
import TableEvaluations from '../TableEvaluations/TableEvaluations';
import TableVoiceMails from '../TableVoiceMails/TableVoiceMail';
import TableDeliveries from '../TableDeliveries/tableDelivery';

const TableLogs: FC = () => {
	const dispatch = useAppDispatch();
	const { tab, searchStart, searchEnd } = useAppSelector(
		(state) => state.viewReducer
	);
	const changeTime = (time: any) => {
		const transformTime = (time: Date) => {
			return `${time.getFullYear()}-${
				time.getMonth() + 1 < 10
					? `0${time.getMonth() + 1}`
					: time.getMonth() + 1
			}-${time.getDate() < 10 ? `0${time.getDate()}` : time.getDate()} ${
				time.getHours() < 10 ? `0${time.getHours()}` : time.getHours()
			}:${
				time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes()
			}`;
		};
		const normalizeTime = (): string => {
			const startTimeNorm = transformTime(time[0]).split(' ');
			const endTimeNorm = transformTime(time[1]).split(' ');
			if (startTimeNorm[1] === '00:00' && endTimeNorm[1] === '00:00') {
				endTimeNorm[1] = '23:59';
			}
			return endTimeNorm.join(' ');
		};
		const endTime = normalizeTime();
		dispatch(setSearchStart(transformTime(time[0])));
		dispatch(setSearchEnd(endTime));
	};

	const handleChange = (event: SelectChangeEvent) => {
		dispatch(setTab(event.target.value as string));
	};

	return (
		<div className="container">
			<p className="label">Журнал событий</p>
			<div className="container__wrapper">
				<div className="wrapper-for_datarange">
					<CustomProvider locale={ruRU}>
						<DateRangePicker
							cleanable={false}
							// ranges={[]}
							onChange={changeTime}
							hoverRange={(date) => [date, date]}
							value={[
								new Date(searchStart.replace(/-/g, '/')),
								new Date(searchEnd.replace(/-/g, '/')),
							]}
							format="yyyy-MM-dd HH:mm"
						/>
					</CustomProvider>
				</div>
				<Box sx={{ minWidth: 120 }}>
					<FormControl fullWidth>
						<InputLabel id="demo-simple-select-label">
							Выберите сценарий
						</InputLabel>
						<Select
							className={'change-input-outlined-tab'}
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={tab}
							label="Выберите сценарий"
							onChange={handleChange}
						>							
							<MenuItem value={'calls'}>Подтверждение заказов</MenuItem>
							<MenuItem value={'video'}>Видеоконтроль упаковки</MenuItem>
							<MenuItem value={'evaluations'}>
								Оценка качества обслуживания
							</MenuItem>
							<MenuItem value={'voiceMails'}>Голосовые рассылки</MenuItem>
							<MenuItem value={'delivery'}>
								Подтверждение доставки
							</MenuItem>
						</Select>
					</FormControl>
				</Box>
			</div>
			{tab === 'calls' ? <TableCalls /> : null}
			{tab === 'video' ? <TableVideo /> : null}
			{tab === 'evaluations' ? <TableEvaluations /> : null}
			{tab === 'voiceMails' ? <TableVoiceMails /> : null}
			{tab === 'delivery' ? <TableDeliveries /> : null}
		</div>
	);
};

export default TableLogs;
