import React, { FC, useEffect, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Tooltip,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import styles from './SettingsDeliveryForm.module.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { updateProfileDeliveris } from '../../service/UsersService';
import { setErrorApp, setInfoApp } from '../../store/reducers/InfoSlice';
import PlayTextButton from '../PlayTextButton/PlayTextButton';
import {
	CALL_TIME,
	DEF_VOICE,
	REJECT_TIME,
	RETRY_CALLS,
	TEXT_VOICE_DELIVIRIES,
	DELIVIRIES_ACTIVATE,
	DELIVERY_CANCELLED,
	DELIVERY_CONFIRMED,
	RECORDING
} from '../../utils/const';
import { fetchProfile } from '../../service/ProfileServiceThunk';
import ReactDOM from 'react-dom';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogContent from '@mui/material/DialogContent';

type SettingsDeliveryFormProps = {
	open: boolean;
	setClose: (value: boolean) => void;
};
const SettingsDeliveryForm: FC<SettingsDeliveryFormProps> = ({ open, setClose }) => {
	const { profile } = useAppSelector((state) => state.profileReducer);
	const voiceValue: string =
		typeof profile?.voice_delivery === 'string'
			? profile?.voice_delivery
			: DEF_VOICE;

	const initTextDeliveries = () => {
		if (profile?.tasks_text_delivery) return profile?.tasks_text_delivery;
		return TEXT_VOICE_DELIVIRIES;
	};

	const initDeliveryСancelled = () => {
		if (profile?.delivery_cancelled) return profile?.delivery_cancelled;
		return DELIVERY_CANCELLED;
	};

	const initDeliveryConfirmed = () => {
		if (profile?.delivery_confirmed) return profile?.delivery_confirmed;
		return DELIVERY_CONFIRMED;
	};
	

	const initDeliveries = () => {
		if (profile?.enable_delivery === true)
			return profile?.enable_delivery;
		return DELIVIRIES_ACTIVATE;
	};

	const initRecording = () => {
		if (profile?.recording_delivery === false)
			return profile?.recording_delivery;
		return RECORDING;
	};

	const initCallTime: string =
		typeof profile?.callTime_delivery === 'string' ? `${profile?.callTime_delivery}` : CALL_TIME;
	const initRejectTime: string =
		typeof profile?.rejectTime_delivery === 'string'
			? `${profile?.rejectTime_delivery}`
			: REJECT_TIME;
	const initRetryCalls: string =
		typeof profile?.retry_call_shop_delivery === 'string'
			? `${profile?.retry_call_shop_delivery}`
			: RETRY_CALLS;


	const [nameDeliveries, setNameDeliveries] = useState<string>(profile?.name_delivery || '');
	const [textDeliveries, setTextDeliveries] = useState<string>(initTextDeliveries);
	const [deliveryСancelled, setDeliveryСancelled] = useState<string>(initDeliveryСancelled);
	const [deliveryConfirmed, setDeliverConfirmed] = useState<string>(initDeliveryConfirmed);
	const [enableDeliveries, setEnableDeliveries] =
		useState<boolean>(initDeliveries);
	const [url, setUrl] = useState<string>(profile?.url_delivery || '');
	const [token, setToken] = useState<string>(profile?.token_access_delivery || '');
	const [retryCall, setRetryCall] = useState<string>(initRetryCalls);
	const [voice, setVoice] = useState<string>(voiceValue);
	const [disabledPlayButton, setPlayButton] = useState<boolean>(true);
	const [callTime, setCallTime] = useState<string>(initCallTime);
	const [rejectTime, setRejectTime] = useState<string>(initRejectTime);
	const [recording, setRecording] = useState<boolean>(initRecording);
	const [expanded, setExpanded] = React.useState<string | false>(false);
	const dispatch = useAppDispatch();

	const handleChangeAccordion =
		(panel: string) => (evt: React.SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false);
		};

	useEffect(() => {
		setNameDeliveries(profile?.name_delivery ?? '');
		setUrl(profile?.url_delivery ?? '');
		setToken(profile?.token_access_delivery ?? '');
		setRetryCall(initRetryCalls);
		setTextDeliveries(initTextDeliveries);
		setDeliveryСancelled(initDeliveryСancelled);
		setDeliverConfirmed(initDeliveryConfirmed);
		setCallTime(initCallTime);
		setRejectTime(initRejectTime);
		setVoice(voiceValue);
		setRecording(initRecording);
		setEnableDeliveries(initDeliveries);
	}, [profile, open]);

	useEffect(() => {
		(textDeliveries.length && deliveryСancelled.length && deliveryConfirmed.length)
			? setPlayButton(false)
			: setPlayButton(true);
	}, [textDeliveries, deliveryСancelled, deliveryConfirmed]);

	const handleChange = (
		evt: React.ChangeEvent<HTMLInputElement>,
		type: string
	) => {
		if (type === 'name_delivery') {
			setNameDeliveries(evt.target.value);
		} else if (type === 'url_delivery') {
			setUrl(evt.target.value);
		} else if (type === 'token_access_delivery') {
			setToken(evt.target.value);
		} else if (type === 'tasks_text_delivery') {
			setTextDeliveries(evt.target.value);
		} else if (type === 'delivery_cancelled') {
			setDeliveryСancelled(evt.target.value);
		} else if (type === 'delivery_confirmed') {
			setDeliverConfirmed(evt.target.value);
		} else if (type === 'retry_call_shop_delivery') {
			const value = evt.target.value;
			const zeroToFourCount = (value.match(/[0-9]/g) || []).length;
			if (
				(zeroToFourCount <= 1 &&
					/^\d$/.test(value) &&
					Number(value) >= 0 &&
					Number(value) <= 9) ||
				value === ''
			) {
				setRetryCall(value);
			}
		} else if (type === 'callTime_delivery') {
			setCallTime(evt.target.value);
		} else if (type === 'rejectTime_delivery') {
			setRejectTime(evt.target.value);
		} 
	};

	const changeHandler = (evt: any, type: string) => {
		if (type === 'enable_delivery') {
			setEnableDeliveries(evt.target.checked);
		} else if (type === 'recording') {
			setRecording(evt.target.checked);
		}
	};

	const handleUpdateProfile = async () => {
		if (
			nameDeliveries.length === 0 ||
			!nameDeliveries.trim() ||
			url.length === 0 ||
			!url.trim() ||
			token.length === 0 ||
			!token.trim() ||
			retryCall.length === 0 ||
			textDeliveries.length === 0 ||
			!textDeliveries.trim() ||
			deliveryСancelled.length === 0 ||
			!deliveryСancelled.trim() ||
						deliveryConfirmed.length === 0 ||
			!deliveryConfirmed.trim()
		) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Необходимо заполнить обязательные поля',
				})
			);
		}

		if (!re.test(callTime) || !re.test(rejectTime)) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Не правильный формат времени(должен быть вида HH:MM)',
				})
			);
		}

		if (compareTime()) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp:
						'Время начала не может быть позже времени окончания звонка',
				})
			);
		}

		if (Number(retryCall) < 0 || Number(retryCall) > 9) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Количество повторных звонков от 0 до 9',
				})
			);
		}

		if (returnText(textDeliveries).length > 500 || returnText(deliveryСancelled).length > 500  || returnText(deliveryConfirmed).length > 500) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Длина текста не должна превышать 500 символов',
				})
			);
		}

		if (nameDeliveries.length > 99) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Наименование магазина не должно превышать 99 символов.',
				})
			);
		}

		const response = await updateProfileDeliveris(
			enableDeliveries,
			nameDeliveries,
			url,
			token,
			textDeliveries,
			deliveryСancelled,
			deliveryConfirmed,
			voice,
			retryCall,
			callTime,
			rejectTime,
			recording
		);
		if (response.error) {
			dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Произошла ошибка при обновление профиля',
				})
			);
		} else {
			dispatch(
				setInfoApp({ isInfoApp: true, infoApp: 'Данные успешно сохранены!' })
			);
			dispatch(fetchProfile());
			setClose(true);
		}
	};

	const handleSelect = (evt: SelectChangeEvent<any>) => {
		setVoice(evt.target.value);
	};

	const re = /^(?:[01]\d|2[0123]):(?:[012345]\d)$/;

	const returnText = (text: string) => {
		return text.replace('<@name_delivery@>', '');
	};

	const compareTime = () => {
		const callTimeArr = callTime.split(':');
		const rejectTimeArr = rejectTime.split(':');
		if (Number(callTimeArr[0]) < Number(rejectTimeArr[0])) {
			return false;
		} else if (
			Number(callTimeArr[0]) === Number(rejectTimeArr[0]) &&
			Number(callTimeArr[1]) < Number(rejectTimeArr[1])
		) {
			return false;
		} else {
			// console.log('Задачу не запускаем')
			return true;
		}
	};

	return ReactDOM.createPortal(
		<Dialog scroll="body" open={open} onClose={() => setClose(true)}>
			<DialogTitle className={styles.profile_label}>
				Настройки подтверждения доставки
				<button onClick={() => setClose(true)} className={styles.close_modal}>
					×
				</button>
			</DialogTitle>
			<div className={styles.container}>
				<div className={styles.wrapper_for_block}>
					<DialogContent className={styles.dialog_content}>
						<FormGroup>
							<FormControlLabel
								control={<Checkbox />}
								onChange={(evt: React.SyntheticEvent<any>) =>
									changeHandler(evt, 'enable_delivery')
								}
								checked={enableDeliveries}
								label="Активировать подтверждение доставки"
								labelPlacement="end"
							/>
						</FormGroup>
					</DialogContent>
				</div>
				<div className={styles.wrapper_for_block}>
					<Accordion
						sx={{ boxShadow: '0px 1px 1px 0px rgba(0,0,0,0.1)' }}
						expanded={expanded === 'panel1'}
						onChange={handleChangeAccordion('panel1')}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1bh-content"
							id="panel1bh-header"
						>
							<Typography>Подключение магазина</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<TextField
								margin="dense"
								id="name_delivery"
								label="Наименование магазина"
								fullWidth
								variant="standard"
								defaultValue={profile?.name_delivery}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'name_delivery')
								}
								error={nameDeliveries.length <= 0 || nameDeliveries.length > 99 || !nameDeliveries.trim()}
							/>
							<TextField
								margin="dense"
								id="url_delivery"
								label="URL обработчика подтверждения доставки"
								fullWidth
								variant="standard"
								defaultValue={profile?.url_delivery}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'url_delivery')
								}
								error={url.length <= 0 || !url.trim()}
							/>
							<TextField
								margin="dense"
								id="token_access_delivery"
								label="Токен доступа"
								fullWidth
								variant="standard"
								defaultValue={profile?.token_access_delivery}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'token_access_delivery')
								}
								error={token.length <= 0 || !token.trim()}
							/>
							<div className="form-group wrapper_instruction">
								<p className={styles.label_for_context_text}>
									Настройка подтверждения доставки
								</p>
								<p>
									1. В поле &quot;URL обработчика подтверждения доставки&quot; укажите
									контроллер внешней системы, который принимает изменения статуса подтверждения адреса доставки.
									<br />
									Например, http://test.ru/test.php
								</p>
								<p>
									2. В поле &quot;Токен доступа&quot; укажите токен, который будет
									использоваться при получении статуса подтверждения адреса доставки со стороны внешних
									систем,
									<br />
									Например, Наименование внешней системы
								</p>
							</div>
						</AccordionDetails>
					</Accordion>
				</div>
				<div className={styles.wrapper_for_block}>
						<Accordion
							sx={{ boxShadow: '0px 1px 1px 0px rgba(0,0,0,0.1)' }}
							expanded={expanded === 'panel2'}
							onChange={handleChangeAccordion('panel2')}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1bh-content"
								id="panel1bh-header"
							>
								<Typography>Сценарий</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<TextField
									margin="dense"
									id="tasks_text_delivery"
									label="Текст (используйте <@name@>, <@text@>, <@delivery_type@>, <@address@> для включения данных в текст)"
									fullWidth
									variant="standard"
									multiline
									maxRows={4}
									value={textDeliveries}
									onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
										handleChange(evt, 'tasks_text_delivery')
									}
									error={textDeliveries.length <= 0 || textDeliveries.length > 500 || !textDeliveries.trim()}
								/>
								<TextField
									margin="dense"
									id="delivery_cancelled"
									label="Отменен"
									fullWidth
									variant="standard"
									multiline
									maxRows={4}
									value={deliveryСancelled}
									onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
										handleChange(evt, 'delivery_cancelled')
									}
									error={deliveryСancelled.length <= 0 || deliveryСancelled.length > 500 || !deliveryСancelled.trim()}
								/>
								<TextField
									margin="dense"
									id="delivery_confirmed"
									label="Подвержден"
									fullWidth
									variant="standard"
									multiline
									maxRows={4}
									value={deliveryConfirmed}
									onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
										handleChange(evt, 'delivery_confirmed')
									}
									error={deliveryConfirmed.length <= 0 || deliveryConfirmed.length > 500 || !deliveryConfirmed.trim()}
								/>
								<InputLabel
									className={styles.voice_input}
									id="demo-simple-select-label"
								>
									Выбор голоса
								</InputLabel>
								<Select
									className={`${styles.voice_select} change-input-outlined`}
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									defaultValue={voice}
									onChange={handleSelect}
								>
									<MenuItem value={'jane'}>jane</MenuItem>
									<MenuItem value={'oksana'}>oksana</MenuItem>
									<MenuItem value={'omazh'}>omazh</MenuItem>
									<MenuItem value={'zahar'}>zahar</MenuItem>
									<MenuItem value={'ermil'}>ermil</MenuItem>
									<MenuItem value={'marina'}>marina</MenuItem>
									<MenuItem value={'lera'}>lera</MenuItem>
									<MenuItem value={'alexander'}>alexander</MenuItem>
									<MenuItem value={'anton'}>anton</MenuItem>
								</Select>
								<PlayTextButton
									voice={voice}
									text={
										textDeliveries + '' + '' + deliveryСancelled + '' + deliveryConfirmed
									}
									disabled={disabledPlayButton}
								/>
							</AccordionDetails>
						</Accordion>
					</div>
				<div className={styles.wrapper_for_block}>
					<Accordion
						sx={{ boxShadow: '0px 1px 1px 0px rgba(0,0,0,0.1)' }}
						expanded={expanded === 'panel3'}
						onChange={handleChangeAccordion('panel3')}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1bh-content"
							id="panel1bh-header"
						>
							<Typography>Дополнительные настройки</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<TextField
								inputProps={{ inputMode: 'numeric', min: 0, max: 9 }}
								margin="dense"
								id="retry_call_shop_delivery"
								label="Повторных звонков"
								fullWidth
								variant="standard"
								value={retryCall}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'retry_call_shop_delivery')
								}
								error={retryCall.length <= 0 || !retryCall.trim()}
							/>
							<TextField
								margin="dense"
								id="callTime_delivery"
								label="Время начала"
								fullWidth
								variant="standard"
								value={callTime}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'callTime_delivery')
								}
								error={callTime.length <= 0 || !callTime.trim()}
							/>
							<TextField
								margin="dense"
								id="rejectTime_delivery"
								label="Прекратить рассылку"
								fullWidth
								variant="standard"
								value={rejectTime}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'rejectTime_delivery')
								}
								error={rejectTime.length <= 0 || !rejectTime.trim()}
							/>		
							<FormGroup>
								<FormControlLabel
									control={<Checkbox />}
									onChange={(evt: React.SyntheticEvent<any>) =>
										changeHandler(evt, 'recording')
									}
									checked={recording}
									label="Включить запись (Внимание! При включении этой опции, в начало обзвона вставляется предупреждение клиента о записи звонка.)"
									labelPlacement="end"
								/>
							</FormGroup>					
						</AccordionDetails>
					</Accordion>
				</div>
				<DialogActions className={styles.dialogActions}>
					<Tooltip
						placement="top"
						title={
							(enableDeliveries === initDeliveries() &&
								nameDeliveries === profile?.name_delivery &&
								url === profile?.voice_delivery &&
								token === profile?.token_access_delivery &&
								callTime === initCallTime &&
								retryCall === initRetryCalls &&
								rejectTime === initRejectTime &&
								voice === voiceValue &&								
								textDeliveries === initTextDeliveries() &&
								deliveryСancelled === initDeliveryСancelled() && 
								deliveryConfirmed == initDeliveryConfirmed() && 
								recording === initRecording())
								? 'Внесите изменения, чтобы сохранить'
								: 'Сохранить'
						}
					>
						<span>
							<Button
								disabled={
									(enableDeliveries === initDeliveries() &&
										nameDeliveries === profile?.name_delivery &&
										url === profile?.url_delivery &&
										token === profile?.token_access_delivery &&
										callTime === initCallTime &&
										retryCall === initRetryCalls &&
										rejectTime === initRejectTime &&
										voice === voiceValue &&										
										textDeliveries === initTextDeliveries() && 
										deliveryСancelled === initDeliveryСancelled() && 
										deliveryConfirmed == initDeliveryConfirmed() && 
										recording === initRecording())
								}
								onClick={handleUpdateProfile}
							>
								Сохранить
							</Button>
						</span>
					</Tooltip>
				</DialogActions>
			</div>
		</Dialog>,
		document.getElementById('devileries')!
	);
};

export default SettingsDeliveryForm;
